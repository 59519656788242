
import { showTicketInfoModal, updateTicketInfo } from "./actions";
import { all, put, takeLatest } from 'redux-saga/effects'
import { showModal } from '../../common/actions'
import { ModalType } from '../../components/Modals/ModalRoot/types'
import { TicketInfoActionTypes } from "./types";



function* handleShowTicketInfoModal(action: ReturnType<typeof showTicketInfoModal>) {

    yield put(
        showModal({
            type: ModalType.TicketInfoModal,
            props: {
            }
        })
    )
}

export function* handleUpdateTicketInfo(action: ReturnType<typeof updateTicketInfo>) {
    const { response } = yield action.payload;
    if (response) {
        yield put(response)
    }
}

function* TicketInfoSaga() {
    yield all([
        takeLatest(TicketInfoActionTypes.SHOW_TICKETINFO_MODAL, handleShowTicketInfoModal),
        takeLatest(TicketInfoActionTypes.UPDATE_TICKETINFO, handleUpdateTicketInfo)

    ])
}


export default TicketInfoSaga 