import moment from 'moment';
import { TicketInfoModel } from '../../common/models';

export const initialState: TicketInfoState = {
  infoo: {
    ticketNo: "TIC2021100004253",
    edittedDate: moment().format('DD/MM/YYYY'),
    ticketName: "KIRMIZI PABUCLAR",
    ticketType: "SATIS",
    paymentMethod: "KREDIKARTI",
    eventDate: moment().format('DD/MM/YYYY'),
  }  
};

export interface TicketInfoStateType {
  ticketInfo: TicketInfoState;
}
export interface TicketInfoState {
  readonly infoo: TicketInfoModel;
}

export enum TicketInfoActionTypes {
  SHOW_TICKETINFO_MODAL = 'SHOW_TICKETINFO_MODAL',
  UPDATE_TICKETINFO = 'UPDATE_TICKETINFO'
}
