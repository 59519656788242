import {
  faArchway,
  faBuilding,
  faCity,
  faDoorOpen,
  faEnvelope,
  faFax,
  faGlobeEurope,
  faListOl,
  faMailBulk,
  faMapMarkerAlt,
  faPhoneVolume,
  faUserPlus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Col, DatePicker, Form, Input, message, Modal, Row, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { countryOption } from '../../../../common/defaultOptions';
import { TicketInfoModel } from '../../../../common/models';
import { updateTicketInfo } from '../../actions';
import { TicketInfoStateType } from '../../types';

interface IProps {
  open: boolean;
  close: () => void;
  ticketInfo: TicketInfoModel;
}

interface IPropsFromDispatch {
  updateTicketInfo: typeof updateTicketInfo;
}
type AllProps = IProps & IPropsFromDispatch & FormComponentProps;

class TicketInfoModal extends Component<AllProps> {
  onClickUpdateTicketInfo = () => {
    this.props.form.validateFields((errors: any, values: TicketInfoModel) => {
      if (errors === null) {
        this.props.updateTicketInfo(values);
        message.success('Kayıt Başarı İle Eklendi!', 2);
        this.props.close();
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        title="Bilet Bilgiler"
        onCancel={() => this.props.close()}
        visible={this.props.open}
        footer={[
          <>
            <Button key="ok" type="primary" icon="check" onClick={() => this.onClickUpdateTicketInfo()}>
              KAYDET
            </Button>
          </>
        ]}
      >
        <Form>
          {/* <Row>
            <Col style={{ marginBottom: 10 }}>
              <Alert
                message="Bilgilendirme"
                description="Buradan gireceğiniz firma bilgileri görselde görmeniz için temsilidir. Asıl firma bilgileriniz hesap açılışınız yapıldığında girilen firma bilgileriniz tasarımınızda görünecektir."
                type="warning"
                showIcon
              />
            </Col>
          </Row> */}
          <Row>
            <Col  >
              <Form.Item hasFeedback>
                {getFieldDecorator('ticketName', {
                  rules: [
                    {
                      required: true,
                      message: 'Burası Girilmesi Zorunlu Alandır!'
                    }
                  ]
                })(
                  <Input
                    prefix={<FontAwesomeIcon style={{ color: '#ced4da' }} icon={faUserPlus} />}
                    placeholder="Bilet İsmi"
                  />
                )}
              </Form.Item>
              <Row>
                <Col span={12}>
                  <Form.Item hasFeedback>
                    {getFieldDecorator('ticketNo', {
                      rules: [
                        {
                          required: true,
                          message: 'Burası Girilmesi Zorunlu Alandır!'
                        }
                      ]
                    })(
                      <Input
                        prefix={<FontAwesomeIcon style={{ color: '#ced4da' }} icon={faListOl} />}
                        placeholder="Bilet Numarası"
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item hasFeedback>
                    {getFieldDecorator('edittedDate', {
                      rules: [
                        {
                          required: true,
                          message: 'Burası Girilmesi Zorunlu Alandır!'
                        }
                      ]
                    })(
                      <DatePicker
                        placeholder="Düzenlenme Tarihi"
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col style={{ paddingRight: 5 }} span={12}>
                  <Form.Item hasFeedback>
                    {getFieldDecorator('ticketType', {
                      rules: [
                        {
                          required: true,
                          message: 'Burası Girilmesi Zorunlu Alandır!'
                        }
                      ]
                    })(
                      <Input
                        prefix={<FontAwesomeIcon style={{ color: '#ced4da' }} icon={faListOl} />}
                        placeholder="Bilet Tipi"
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item hasFeedback>
                    {getFieldDecorator('eventDate', {
                      rules: [
                        {
                          required: true,
                          message: 'Burası Girilmesi Zorunlu Alandır!'
                        }
                      ]
                    })(
                      <DatePicker
                        placeholder="Etkinlik Tarihi"
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item hasFeedback>
                    {getFieldDecorator('paymentMethod', {
                      rules: [
                        {
                          required: true,
                          message: 'Burası Girilmesi Zorunlu Alandır!'
                        }
                      ]
                    })(
                      <Input
                        prefix={<FontAwesomeIcon style={{ color: '#ced4da' }} icon={faPhoneVolume} />}
                        placeholder="Ödeme Tipi"
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {/* <Col span={12} style={{ paddingLeft: 10 }}>
              <Form.Item hasFeedback>
                {getFieldDecorator('address', {
                  rules: [{ required: false }]
                })(
                  <Input
                    prefix={<FontAwesomeIcon style={{ color: '#ced4da' }} icon={faMapMarkerAlt} />}
                    placeholder="Adres"
                  />
                )}
              </Form.Item>
              <Row>
                <Col style={{ paddingRight: 5 }} span={8}>
                  <Form.Item hasFeedback>
                    {getFieldDecorator('district', {
                      rules: [
                        {
                          required: true,
                          message: 'Burası Girilmesi Zorunlu Alandır!'
                        }
                      ]
                    })(
                      <Input
                        prefix={<FontAwesomeIcon style={{ color: '#ced4da' }} icon={faArchway} />}
                        placeholder="Mahalle/Semt/İlçe"
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col style={{ paddingRight: 5, paddingLeft: 5 }} span={8}>
                  <Form.Item hasFeedback>
                    {getFieldDecorator('city', {
                      rules: [
                        {
                          required: true,
                          message: 'Burası Girilmesi Zorunlu Alandır!'
                        }
                      ]
                    })(
                      <Input
                        prefix={<FontAwesomeIcon style={{ color: '#ced4da' }} icon={faCity} />}
                        placeholder="Şehir"
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col style={{ paddingLeft: 5 }} span={8}>
                  <Form.Item hasFeedback>
                    {getFieldDecorator('country', {
                      rules: [
                        {
                          required: true,
                          message: 'Ülke Girilmesi Zorunludur!'
                        }
                      ]
                    })(
                      <Select
                        suffixIcon={<FontAwesomeIcon style={{ color: '#ced4da' }} icon={faGlobeEurope} />}
                        placeholder="Ülke"
                        style={{ width: '100%' }}
                      >
                        {countryOption.map(option => (
                          <Select.Option key={option.key} value={option.value}>
                            {option.value}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col style={{ paddingRight: 5 }} span={8}>
                  <Form.Item hasFeedback>
                    {getFieldDecorator('postalCode', {
                      rules: [
                        {
                          required: false
                        }
                      ]
                    })(
                      <Input
                        prefix={<FontAwesomeIcon style={{ color: '#ced4da' }} icon={faMailBulk} />}
                        placeholder="Posta Kodu"
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col style={{ paddingLeft: 5, paddingRight: 5 }} span={8}>
                  <Form.Item hasFeedback>
                    {getFieldDecorator('buildingNumber', {
                      rules: [
                        {
                          required: false
                        }
                      ]
                    })(
                      <Input
                        prefix={<FontAwesomeIcon style={{ color: '#ced4da' }} icon={faBuilding} />}
                        placeholder="Bina No"
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col style={{ paddingLeft: 5 }} span={8}>
                  <Form.Item hasFeedback>
                    {getFieldDecorator('doorNumber', {
                      rules: [
                        {
                          required: false
                        }
                      ]
                    })(
                      <Input
                        prefix={<FontAwesomeIcon style={{ color: '#ced4da' }} icon={faDoorOpen} />}
                        placeholder="Kapı No"
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item hasFeedback>
                    {getFieldDecorator('mail', {
                      rules: [
                        {
                          required: false,
                          type: 'email',
                          message: "E-Mail adresi '@' İşareti içermelidir!"
                        }
                      ]
                    })(
                      <Input
                        prefix={<FontAwesomeIcon style={{ color: '#ced4da' }} icon={faEnvelope} />}
                        placeholder="Mail Adresi"
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item hasFeedback>
                    {getFieldDecorator('webSite', {
                      rules: [
                        {
                          required: false
                        }
                      ]
                    })(<Input placeholder="İnternet Sitesi" />)}
                  </Form.Item>
                </Col>
              </Row>
            </Col> */}
          </Row>
        </Form>
      </Modal>
    );
  }
}

const WrappedTicketForm = Form.create<AllProps>()(TicketInfoModal);

const mapStateToProps = ({ ticketInfo }: TicketInfoStateType) => ({
  ticketInfo: ticketInfo.infoo
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateTicketInfo: (params: TicketInfoModel) => dispatch(updateTicketInfo(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(WrappedTicketForm);
