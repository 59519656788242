import { Option } from '../common/optionsTypes';

export const countryOption: Option[] = [
  { key: 1, value: 'Abhazya' },
  { key: 2, value: 'Afganistan' },
  { key: 3, value: 'Almanya' },
  { key: 4, value: 'Amerika Birleşik Devletleri' },
  { key: 5, value: 'Andora' },
  { key: 6, value: 'Angola' },
  { key: 7, value: 'Antigua ve Barbuda' },
  { key: 8, value: 'Arjantin' },
  { key: 9, value: 'Arnavutluk' },
  { key: 10, value: 'Avustralya' },
  { key: 11, value: 'Avusturya' },
  { key: 12, value: 'Azerbaycan' },
  { key: 13, value: 'Bahamalar' },
  { key: 14, value: 'Bahreyn' },
  { key: 15, value: 'Bangladeş' },
  { key: 16, value: 'Barbados' },
  { key: 17, value: 'Batı Sahra' },
  { key: 18, value: 'Belçika' },
  { key: 19, value: 'Belize' },
  { key: 20, value: 'Benin' },
  { key: 21, value: 'Beyaz Rusya' },
  { key: 22, value: 'Bhutan' },
  { key: 23, value: 'Birleşik Arap Emirlikleri' },
  { key: 24, value: 'Bolivya' },
  { key: 25, value: 'Bosna-Hersek' },
  { key: 26, value: 'Botsvana' },
  { key: 27, value: 'Brezilya' },
  { key: 28, value: 'Brunei' },
  { key: 29, value: 'Bulgaristan' },
  { key: 30, value: 'Burkina Faso' },
  { key: 31, value: 'Burundi' },
  { key: 32, value: 'Cezayir' },
  { key: 33, value: 'Cibuti' },
  { key: 34, value: 'Çad' },
  { key: 35, value: 'Çek Cumhuriyeti' },
  { key: 36, value: 'Çin' },
  { key: 37, value: 'Dağlık Karabağ Cumhuriyeti' },
  { key: 38, value: 'Danimarka' },
  { key: 39, value: 'Doğu Timor' },
  { key: 40, value: 'Dominik Cumhuriyeti' },
  { key: 41, value: 'Dominika' },
  { key: 42, value: 'Ekvador' },
  { key: 43, value: 'Ekvator Ginesi' },
  { key: 44, value: 'El Salvador' },
  { key: 45, value: 'Endonezya' },
  { key: 46, value: 'Eritre' },
  { key: 47, value: 'Ermenistan' },
  { key: 48, value: 'Estonya' },
  { key: 49, value: 'Etiyopya' },
  { key: 50, value: 'Fas' },
  { key: 51, value: 'Fiji' },
  { key: 52, value: 'Fildişi Sahili' },
  { key: 53, value: 'Filipinler' },
  { key: 54, value: 'Filistin' },
  { key: 55, value: 'Finlandiya' },
  { key: 56, value: 'Fransa' },
  { key: 57, value: 'Gabon' },
  { key: 58, value: 'Gambiya' },
  { key: 59, value: 'Gana' },
  { key: 60, value: 'Gine' },
  { key: 61, value: 'Gine Bissau' },
  { key: 62, value: 'Grenada' },
  { key: 63, value: 'Guyana' },
  { key: 64, value: 'Guatemala' },
  { key: 65, value: 'Güney Afrika Cumhuriyeti' },
  { key: 66, value: 'Güney Kore' },
  { key: 67, value: 'Güney Osetya' },
  { key: 68, value: 'Güney Sudan' },
  { key: 69, value: 'Gürcistan' },
  { key: 70, value: 'Haiti' },
  { key: 71, value: 'Hırvatistan' },
  { key: 72, value: 'Hindistan' },
  { key: 73, value: 'Hollanda' },
  { key: 74, value: 'Honduras' },
  { key: 75, value: 'Irak' },
  { key: 76, value: 'İngiltere' },
  { key: 77, value: 'İran' },
  { key: 78, value: 'İrlanda' },
  { key: 79, value: 'İspanya' },
  { key: 80, value: 'İsrail' },
  { key: 81, value: 'İsveç' },
  { key: 82, value: 'İsviçre' },
  { key: 83, value: 'İtalya' },
  { key: 84, value: 'İzlanda' },
  { key: 85, value: 'Jamaika' },
  { key: 86, value: 'Japonya' },
  { key: 87, value: 'Kamboçya' },
  { key: 88, value: 'Kamerun' },
  { key: 89, value: 'Kanada' },
  { key: 90, value: 'Karadağ' },
  { key: 91, value: 'Katar' },
  { key: 92, value: 'Kazakistan' },
  { key: 93, value: 'Kenya' },
  { key: 94, value: 'Kırgızistan' },
  { key: 95, value: 'Kıbrıs Cumhuriyeti' },
  { key: 96, value: 'Kiribati' },
  { key: 97, value: 'Kolombiya' },
  { key: 98, value: 'Komorlar' },
  { key: 99, value: 'Kongo' },
  { key: 100, value: 'Kongo DC' },
  { key: 101, value: 'Kosova' },
  { key: 102, value: 'Kostarika' },
  { key: 103, value: 'Kuveyt' },
  { key: 104, value: 'Kuzey Kıbrıs Türk Cumhuriyeti' },
  { key: 105, value: 'Kuzey Kore' },
  { key: 106, value: 'Küba' },
  { key: 107, value: 'Laos' },
  { key: 108, value: 'Lesotho' },
  { key: 109, value: 'Letonya' },
  { key: 110, value: 'Liberya' },
  { key: 111, value: 'Libya' },
  { key: 112, value: 'Lihtenştayn' },
  { key: 113, value: 'Litvanya' },
  { key: 114, value: 'Lübnan' },
  { key: 115, value: 'Lüksemburg' },
  { key: 116, value: 'Macaristan' },
  { key: 117, value: 'Madagaskar' },
  { key: 118, value: 'Kuzey Makedonya' },
  { key: 119, value: 'Malavi' },
  { key: 120, value: 'Maldiv Adaları' },
  { key: 121, value: 'Malezya' },
  { key: 122, value: 'Mali' },
  { key: 123, value: 'Malta' },
  { key: 124, value: 'Marshall Adaları' },
  { key: 125, value: 'Meksika' },
  { key: 126, value: 'Mısır' },
  { key: 127, value: 'Mikronezya' },
  { key: 128, value: 'Moğolistan' },
  { key: 129, value: 'Moldova' },
  { key: 130, value: 'Monako' },
  { key: 131, value: 'Moritanya' },
  { key: 132, value: 'Moritius' },
  { key: 133, value: 'Mozambik' },
  { key: 134, value: 'Myanmar' },
  { key: 135, value: 'Namibya' },
  { key: 136, value: 'Nauru' },
  { key: 137, value: 'Nepal' },
  { key: 138, value: 'Nikaragua' },
  { key: 139, value: 'Nijer' },
  { key: 140, value: 'Nijerya' },
  { key: 141, value: 'Norveç' },
  { key: 142, value: 'Orta Afrika Cumhuriyeti' },
  { key: 143, value: 'Özbekistan' },
  { key: 144, value: 'Pakistan' },
  { key: 145, value: 'Palau' },
  { key: 146, value: 'Panama' },
  { key: 147, value: 'Papua Yeni Gine' },
  { key: 148, value: 'Paraguay' },
  { key: 149, value: 'Peru' },
  { key: 150, value: 'Polonya' },
  { key: 151, value: 'Portekiz' },
  { key: 152, value: 'Romanya	' },
  { key: 153, value: 'Ruanda' },
  { key: 154, value: 'Rusya' },
  { key: 155, value: 'Saint Kitts ve Nevis' },
  { key: 156, value: 'Saint Lucia' },
  { key: 157, value: 'Saint Vincent ve Grenadinler' },
  { key: 158, value: 'Samoa' },
  { key: 159, value: 'San Marino' },
  { key: 160, value: 'Sao Tome ve Principe' },
  { key: 161, value: 'Sealand' },
  { key: 162, value: 'Senegal' },
  { key: 163, value: 'Seyşeller' },
  { key: 164, value: 'Sırbistan' },
  { key: 165, value: 'Sierra Leone' },
  { key: 166, value: 'Singapur' },
  { key: 167, value: 'Slovakya' },
  { key: 168, value: 'Slovenya' },
  { key: 169, value: 'Solomon' },
  { key: 170, value: 'Somali' },
  { key: 171, value: 'Somaliland' },
  { key: 172, value: 'Sri Lanka' },
  { key: 173, value: 'Sudan' },
  { key: 174, value: 'Surinam' },
  { key: 175, value: 'Suriye' },
  { key: 176, value: 'Suudi Arabistan' },
  { key: 177, value: 'Esvatini' },
  { key: 178, value: 'Şili' },
  { key: 179, value: 'Tacikistan' },
  { key: 180, value: 'Tanzanya' },
  { key: 181, value: 'Tayland' },
  { key: 182, value: 'Tayvan' },
  { key: 183, value: 'Togo' },
  { key: 184, value: 'Tonga' },
  { key: 185, value: 'Transdinyester' },
  { key: 186, value: 'Trinidad ve Tobago' },
  { key: 187, value: 'Tunus' },
  { key: 188, value: 'Tuvalu' },
  { key: 189, value: 'Türkiye' },
  { key: 190, value: 'Türkmenistan' },
  { key: 191, value: 'Uganda' },
  { key: 192, value: 'Ukrayna' },
  { key: 193, value: 'Umman' },
  { key: 194, value: 'Uruguay' },
  { key: 195, value: 'Ürdün' },
  { key: 196, value: 'Vanuatu' },
  { key: 197, value: 'Vatikan' },
  { key: 198, value: 'Venezuela' },
  { key: 199, value: 'Vietnam' },
  { key: 200, value: 'Yemen' },
  { key: 201, value: 'Yeni Zelanda' },
  { key: 202, value: 'Yeşil Burun Adaları' },
  { key: 203, value: 'Yunanistan' },
  { key: 204, value: 'Zambiya' },
  { key: 205, value: 'Zimbabve' }
];
